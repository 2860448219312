<template>
  <section class="page_cell">

    <el-card>
      <m-search
          :searchItemList="searchItemList"
          @on-search-submit="onSearchSubmit"
          @search="onSearchSubmit"
      ></m-search>

      <div class="flex-center radio-cell" style="justify-content: flex-start">
        <el-radio-group v-model="search.eventStatus" @input="getList()">
          <el-radio-button >全部({{ count }})</el-radio-button>
          <el-radio-button :label="1">报名未开始({{ waitCount }})</el-radio-button>
          <el-radio-button :label="2">报名进行中({{ onCount }})</el-radio-button>
          <el-radio-button :label="3">报名已截止({{ offCount }})</el-radio-button>
          <el-radio-button :label="4">活动已结束({{ endCount }})</el-radio-button>
          <el-radio-button :label="0">Banner展示({{ bannerCount }})</el-radio-button>
        </el-radio-group>
        <el-button
            class="mlr-sm"
            type="primary"
            style="margin-top: 10px; margin-bottom: 10px; margin-left: 10px"
            @click="addActivity"
        >新增活动
        </el-button
        >
      </div>
      <el-table
          :data="tableList"
          stripe
          border
          :header-cell-style="{
            'font-size': '14px',
            color: '#909399',
            'font-weight': '400',
            background: '#F7F8FA',
          }"
          style="width: 100%"
          v-loading="loading"
      >
        <el-table-column prop="publicTime" align="center" label="发布时间">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.publicTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="活动标题">
        </el-table-column>
        <el-table-column align="center" label="活动时间" width="360">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.eventStartTime }} 至 {{ scope.row.eventEndTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <div class="flex_center">
              <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  @change="changeStatus($event, scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Banner展示">
          <template slot-scope="scope">
            <div class="flex_center">
              <el-switch
                  v-model="scope.row.bannerShowFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="changeStatus2($event, scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="joinCount" align="center" label="报名人数">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.joinCount }}
            </div>
            <el-button type="text" @click="prevShowJointModal(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="replyCount" align="center" label="活动评论">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.replyCount }}
            </div>
            <el-button type="text" @click="prevShowCommentModal(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="prevShowEditModal(scope.row)">编辑</el-button>
              <el-button
                  type="text"
                  @click="deleteItem(scope.row)"
                  style="color: #f0232d"
              >删除
              </el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="tableList.length > 0" class="flex_end p-sm">
        <el-pagination
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="search.page"
            :page-size="search.pageSize"
            layout="total,sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
        title="报名列表"
        :visible.sync="showJointModal"
        :append-to-body="true"
        width="80%"
    >
      <el-card>
        <m-search
            :searchItemList="eventUserSearchItemList"
            @on-search-submit="onEventUserSearchSubmit"
            @search="onEventUserSearchSubmit"
        ></m-search>
        <div class="flex-center radio-cell" style="justify-content: flex-start">
          <el-radio-group v-model="eventUserSearch.status" @input="getEventUserList(eventUserSearch.id)">
            <el-radio-button label>全部({{ eventUserCount }})</el-radio-button>
            <el-radio-button :label="1">已报名({{ eventUserConfirmCount }})</el-radio-button>
            <el-radio-button :label="0">取消报名({{ eventUserCancelCount }})</el-radio-button>
          </el-radio-group>
        </div>
        <el-table
            :data="eventUserTableList"
            stripe
            border
            :header-cell-style="{
          'font-size': '14px',
          color: '#909399',
          'font-weight': '400',
          background: '#F7F8FA',
        }"
            style="width: 100%"
            v-loading="loading"
        >
          <el-table-column align="center" label="报名时间">
            <template slot-scope="scope">
              <div class="flex_center">
                {{ scope.row.createdAt }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="名称">
            <template slot-scope="scope">
              <div class="flex_center">
                <div>{{ scope.row.name || "-" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="电话">
            <template slot-scope="scope">
              <div class="flex_center">
                <div>{{ scope.row.phone || "-" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="单位">
            <template slot-scope="scope">
              <div class="flex_center">
                <div>{{ scope.row.company || "-" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="作品介绍">
            <template slot-scope="scope">
              <div class="flex_center">
                <div>{{ scope.row.content || "-" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="图片">
            <template slot-scope="scope">
              <div class="flex_center" v-if="scope.row.images && scope.row.images.length > 0 && scope.row.images[0]">
                <el-image
                    style="width: 120px; height: 120px"
                    :src="scope.row.images[0].imageUrl"
                    :fit="cover"
                    :preview-src-list="[scope.row.images[0].imageUrl]">
                </el-image>
              </div>
            </template>
          </el-table-column>
<!--          <el-table-column align="center" label="创意名称">-->
<!--            <template slot-scope="scope">-->
<!--              <div class="flex_center">-->
<!--                <div>{{ scope.row.creativeName || "-" }}</div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="prevShowUserImageModal(scope.row)">查看图片</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <div class="flex_center">
                <div v-if="scope.row.status === 0" style="color: #999999;">取消报名</div>
                <div v-if="scope.row.status === 1" style="color: #0084FF">已报名</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableList.length > 0" class="flex_end p-sm">
          <el-pagination
          @current-change="handleCurrentChange1"
            @size-change="handleSizeChange1"
              :current-page.sync="eventUserSearch.page"
              :page-size="eventUserSearch.pageSize"
              layout="total,sizes, prev, pager, next, jumper"
              :total="eventUserTotal">
          </el-pagination>
        </div>
      </el-card>
    </el-dialog>
    <el-dialog
        title="活动评论"
        :visible.sync="showCommentModal"
        :append-to-body="true"
        width="80%"
    >
      <el-card>
        <!--        <el-button type="primary" @click="prevShowAddCommentModal">新增评论</el-button>-->
        <m-search
            :searchItemList="eventReplySearchItemList"
            @on-search-submit="onEventReplySearchSubmit"
            @search="onEventReplySearchSubmit"
        ></m-search>
        <el-table
            :data="eventReplyTableList"
            stripe
            border
            :header-cell-style="{
          'font-size': '14px',
          color: '#909399',
          'font-weight': '400',
          background: '#F7F8FA',
        }"
            style="width: 100%"
            v-loading="loading"
        >
          <el-table-column align="center" label="发布时间">
            <template slot-scope="scope">
              <div class="flex_center">
                {{ scope.row.createdAt }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="昵称">
            <template slot-scope="scope">
              <div class="flex_center">
                <div>{{ scope.row.wxUser.nickname || "-" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="内容">
            <template slot-scope="scope">
              <div class="flex_center">
                <div>{{ scope.row.msg || "-" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="图片">
            <template slot-scope="scope">
              <div class="flex_center">
                <div >
                  <img :src="image.imageUrl" class="pinglun_img"  v-for="image in scope.row.images" :key="image.imageUrl"/>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <div class="flex_center">
                <el-switch
                    v-model="scope.row.status"
                    :active-value="1"
                    :inactive-value="0"
                    @change="changeEventReplyStatus($event, scope.row)"
                >
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="prevShowAddCommentModal(scope.row)">编辑</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableList.length > 0" class="flex_end p-sm">
          <el-pagination
          @current-change="handleCurrentChange2"
            @size-change="handleSizeChange2"
              :current-page.sync="eventReplySearch.page"
              :page-size="eventReplySearch.pageSize"
              layout="total,sizes, prev, pager, next, jumper"
              :total="eventReplyCount">
          </el-pagination>
        </div>
      </el-card>
    </el-dialog>
    <el-dialog
        title="编辑评论"
        :visible.sync="showAddCommentModal"
        :append-to-body="true"
        width="50%"
    >
      <el-card>
        <el-form ref="form" :model="replyOne" label-width="80px">
          <el-form-item label="内容">
            <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="replyOne.msg"></el-input>
          </el-form-item>
          <el-form-item label="上传图片:">
            <m-uploader type="many" :limit="6" :fileList.sync="replyOne.images"></m-uploader>
          </el-form-item>
          <el-form-item label="上架">
            <el-switch v-model="replyOne.status"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onReplyOneSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-dialog>
    <el-dialog
        title="图片详情"
        :visible.sync="showUserImageModal"
        :append-to-body="true"
        width="60%"
    >
      <div class="image-list">
        <div v-for="image in multipleImages" :key="image.id" class="image-item">
          <el-image
              style="width: 120px; height: 120px"
              :src="image.imageUrl"
              :fit="cover"
              :preview-src-list="[image.imageUrl]">
          </el-image>
        </div>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import Activity, {editEventReplyOne, editEventReplyStatus, getEventReplyOne,delActivity} from '@/axios/api/activity.js'
import Market from "@/axios/api/market";

export default {
  data() {
    return {
      search: {
        eventStatus: "", //活动状态：1-报名未开始 2-报名中 3报名已截止 4-活动进行中 5-活动已结束
        title: "",
        page: 1,
        pageSize: 10,
        start: '',
        end: '',
      },
      eventUserSearch: {
        page: 1,
        pageSize: 10,
        name: '',
        phone: '',
        company: '',
        id: '',
        status: ''
      },
      eventReplySearch: {
        page: 1,
        pageSize: 10,
        name: '',
        msg: '',
        id: ''
      },
      obj: {},
      tableList: [],
      eventUserTableList: [],
      eventReplyTableList: [],
      objSum: 0,
      loading: false,
      count: 0, //总条数
      downCount: 0,
      upCount: 0,
      classifyList: [],
      bannerCount: 0,
      waitCount: 0, // 未开始
      onCount: 0, // 报名中
      offCount: 0, //报名截止
      endCount: 0, //活动结束
      showEditModal: false, //新增编辑活动弹窗
      showJointModal: false, //报名人数弹窗
      showCommentModal: false, //活动评论弹窗
      showAddCommentModal: false, //新增/编辑活动评论弹窗
      eventUserCount: 0,//报名人数弹窗总数
      eventUserConfirmCount: 0,//报名人数弹窗已报名
      eventUserCancelCount: 0,//报名人数弹窗未报名
      eventReplyCount: 0,///活动评论弹窗总数
      showUserImageModal: false, //活动报名图片弹窗
      multipleImages: [], //活动报名图片弹窗 图片
      replyOne: {
        id: '',
        msg: '',
        images: [],
        status: ''
      }
    }
  },
  computed: {
    searchItemList() {
      return [
        {
          label: "标题",
          type: "input",
          param: "title",
        },
        {
          label: "日期",
          type: "datetimerange",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
          param: "dateInterval",
          defaultSelect: "", // 下拉框默认选中项
        },
      ];
    },
    //活动列表底下的总条数
    total() {
      let countArr = ['bannerCount','waitCount','onCount','offCount','endCount']
      let total = this.search.eventStatus ? this[countArr[this.search.eventStatus]] : this.count
      total = this.search.eventStatus == '0' ? this.bannerCount : total
      return total
    },
    //报名列表底下的总条数
    eventUserTotal() {
      let countArr = ['eventUserCancelCount','eventUserConfirmCount']
      let total = this.eventUserSearch.status ? this[countArr[this.eventUserSearch.status]] : this.eventUserCount
      return total
    },
    eventUserSearchItemList() {
      return [
        {
          label: "名称",
          type: "input",
          param: "name",
        },
        {
          label: "电话",
          type: "input",
          param: "phone",
        },
        {
          label: "单位",
          type: "input",
          param: "company",
        },
      ];
    },
    eventReplySearchItemList() {
      return [
        {
          label: "昵称",
          type: "input",
          param: "name",
        },
        {
          label: "内容",
          type: "input",
          param: "msg",
        }
      ];
    },
  },
  created() {
    this.getList()
  },
  methods: {
        // 商品上架或下架
    changeStatus(e, row) {
      let oldStatus = row.status == 0 ? 1 : 0
      console.log(oldStatus);
      let that = this;
      row.status = oldStatus
      that.$Modal.confirm({
        title: "提示",
        content: `是否${oldStatus == 0 ? '上' : '下'}架，请确认`,
        onOk: () => {
          Activity.editStatus({id: row.id, status: oldStatus}).then((res) => {
            console.log(res);
            if (res.status == 1) {
              that.getList();
              that.$message.success("修改成功！");
            }
          });
        },
      });
    },
    // 删除列表项
    deleteItem(row) {
      let that = this
      this.$Modal.confirm({
        title: '提示',
        content: '是否删除，请确认',
        onOk: async () => {
          let res = await delActivity({id: row.id})
          if (res.status == 1) {
            that.getList()
          }
        }
      });
    },
    //是否banner展示
    changeStatus2(e, row) {
      let oldStatus = row.bannerShowFlag == 0 ? 1 : 0
      let that = this;
      row.bannerShowFlag = oldStatus
      that.$Modal.confirm({
        title: "提示",
        content: `是否${oldStatus == 0 ? '在' : '取消'}Banner展示，请确认`,
        onOk: () => {
          Activity.editBannerShow({id: row.id, bannerShow: oldStatus}).then((res) => {
            console.log(res);
            if (res.status == 1) {
              that.getList();
              that.$message.success("修改成功！");
            }
          });
        },
      });
    },
    //查询
    onSearchSubmit(item) {
      console.log("item", item);
      if (item.dateInterval) {
        this.search.start = item.dateInterval[0];
        this.search.end = item.dateInterval[1];
      } else {
        this.search.start = "";
        this.search.end = "";
      }
      this.search.title = item.title;
      console.log("this.search", this.search)
      this.getList();
    },
    //新增活动
    addActivity() {
      this.$router.push({path: "/activity/edit"});
    },
    //获取列表数据
    async getList(val) {
      let res = await Activity.getEventList(this.search);
      console.log("111");
      console.log(res)
      if (res.data.status == 1) {
        this.tableList = res.data.data || []
        this.count = res.data.extra.count || 0
        this.bannerCount = res.data.extra.bannerCount || 0
        this.waitCount = res.data.extra.waitCount || 0
        this.onCount = res.data.extra.onCount || 0
        this.offCount = res.data.extra.offCount || 0
        this.endCount = res.data.extra.endCount || 0
      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.getList();
    },
    handleSizeChange1(val) {
      this.eventUserSearch.pageSize = val;
      this.getEventUserList(this.eventUserSearch.id);
    },
    handleCurrentChange1(val) {
      this.eventUserSearch.page = val;
      this.getEventUserList(this.eventUserSearch.id);
    },
    handleSizeChange2(val) {
      this.eventReplySearch.pageSize = val;
      this.getEventReplyList(this.eventReplySearch.id);
    },
    handleCurrentChange2(val) {
      this.eventReplySearch.page = val;
      this.getEventReplyList(this.eventReplySearch.id);
    },
    //提交
    onReplyOneSubmit() {
      console.log(this.replyOne);
      this.replyOne.status = this.replyOne.status === true ? 1 : 0
      console.log(this.replyOne);
      Activity.editEventReplyOne(this.replyOne).then(({data:res}) => {
        if (res.status == 1) {
          this.showAddCommentModal = false
          this.$message.success("修改成功！");
          // this.getEventReplyList(this.eventReplySearch.id);
        }
      });
    },
    //重置
    reSet() {

    },
    changeEventReplyStatus(e, row, type) {
      let oldStatus = row.status == 0 ? 1 : 0
      Activity.editEventReplyStatus({id: row.id, status: oldStatus}).then((res) => {
        if (res.status == 1) {
          row.status = oldStatus;
          this.$message.success("修改成功！");
          this.getEventReplyList(this.eventReplySearch.id);
        }
      });
    },
    prevShowJointModal(row) {
      this.showJointModal = true
      this.getEventUserList(row.id)
    },
    prevShowCommentModal(row) {
      this.showCommentModal = true
      this.getEventReplyList(row.id)
    },
    // 打开评论编辑弹窗
    prevShowAddCommentModal(row) {
      this.showAddCommentModal = true
      row.images.forEach(item => {
        item.url = item.imageUrl
      });
      row.status = row.status === 1
      this.replyOne = row
      console.log(this.replyOne);
      // if (row.id) {
      //   this.getEventReplyOne(row.id)
      // }
    },
    prevShowEditModal(row) {
      this.showEditModal = true
      this.$router.push({path: "/activity/edit", query: {id: row.id}});
    },
    //获取活动用户列表数据
    async getEventUserList(id) {
      this.eventUserSearch.id = null
      let res = await Activity.getEventUserList(id, this.eventUserSearch);
      console.log(res)
      if (res.data.status == 1) {
        this.eventUserSearch.id = id
        this.eventUserTableList = res.data.data
        this.eventUserCount = res.data.extra.count || 0
        this.eventUserConfirmCount = res.data.extra.confirmCount || 0
        this.eventUserCancelCount = res.data.extra.cancelCount || 0
      }
    },
    //查询
    onEventUserSearchSubmit(item) {
      this.eventUserSearch.name = item.name;
      this.eventUserSearch.phone = item.phone;
      this.eventUserSearch.company = item.company;
      this.getEventUserList(this.eventUserSearch.id);
    },
    //获取活动评论列表数据
    async getEventReplyList(id) {
      this.eventReplySearch.id = null
      let res = await Activity.getEventReplyList(id, this.eventReplySearch);
      console.log(res)
      if (res.data.status == 1) {
        this.eventReplySearch.id = id
        this.eventReplyTableList = res.data.data
        this.eventReplyCount = res.data.extra.count || 0
      }
    },
    //查询
    onEventReplySearchSubmit(item) {
      this.eventReplySearch.name = item.name;
      this.eventReplySearch.msg = item.msg;
      this.getEventReplyList(this.eventReplySearch.id);
    },
    // //获取活动评论单条数据
    // async getEventReplyOne(id) {
    //   let res = await Activity.getEventReplyOne(id);
    //   if (res.data.status === 1) {
    //     this.replyOne.id = id
    //     this.replyOne = res.data.data
    //     this.replyOne.status = this.replyOne.status === 1
    //   }
    // },
    prevShowUserImageModal(row) {
      this.showUserImageModal = true
      this.multipleImages = row.images
    },
  }
}
</script>
<style lang="less">
.flex_end {
 display: flex;
 justify-content: flex-end;
}
.page_cell {
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
}

.radio-cell .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #E6F1FC;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #A3D0FD;
  color: #1989FA;
}

.search {
  padding: 20px;
  background: #F7F8FA;
  border: 2px;
  padding-bottom: 10px;
}

.pinglun_img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  margin: 0 4px;
}

.image-item {
  width: 120px;
  height: 120px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 16px 16px 0;
  display: inline-block;
  position: relative;
}
</style>
