import Axios from '../axios.js';

//获取活动列表,params会拼接在请求链接上
export const getEventList = (data) => {
    return Axios({
        url: `/api/admin/event/eventList`,
        method: 'get',
        params: {
            ...data
        }
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

//获取活动详情
export const getDetail = (params) => {
    return Axios({
        url: `/api/admin/event/${params.id}`,
        method: 'get',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

export const addEvent = (data) => {
    return Axios({
        url: `/api/admin/event`,
        method: 'post',
        data: {...data}
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
} 

export const updateEvent = (id, data) => {
    return Axios({
        url: `/api/admin/event/${id}`,
        method: 'put',
        data: {...data}
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

export const getEventUserList = (id, data) => {
    return Axios({
        url: `/api/admin/event/eventUser/${id}`,
        method: 'get',
        params: {
            ...data
        }
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

export const getEventReplyList = (id, data) => {
    return Axios({
        url: `/api/admin/event/reply/${id}`,
        method: 'get',
        params: {
            ...data
        }
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

export const getEventReplyOne = (id) => {
    return Axios({
        url: `/api/admin/event/eventReply/${id}`,
        method: 'get',
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}


export const editEventReplyStatus = (params) => {
    return Axios({
        url: `/api/admin/event/reply/${params.id}`,
        method: 'put',
        params
    }).then(
        res => Promise.resolve(res.data),
        err => Promise.reject(err)
    );
}

export const editEventReplyOne = (data) => {
    return Axios({
        url: `/api/admin/event/reply`,
        method: 'put',
        data: {...data}
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}
// 修改状态
export const editStatus = (params) =>{
    return Axios({
      url: `/api/admin/event/status/${params.id}`,
      method: 'put',
      params
    }).then(
      res => Promise.resolve(res.data),
      err => Promise.reject(err)
    );
  }
  
  // 修改Banner展示
  export const editBannerShow = (params) =>{
    return Axios({
      url: `/api/admin/event/bannerShow/${params.id}`,
      method: 'put',
      params
    }).then(
      res => Promise.resolve(res.data),
      err => Promise.reject(err)
    );
  }

  //删除活动
  export const delActivity = (params) =>{
    return Axios({
      url: `/api/admin/event/${params.id}`,
      method: 'delete',
      data: params
    }).then(
      res => Promise.resolve(res.data),
      err => Promise.reject(err)
    );
  }

// 在下面写就包含对象引入，以上属于可以解构的
export default {
    getEventList,
    addEvent,
    updateEvent,
    getEventUserList,
    getEventReplyList,
    getEventReplyOne,
    editEventReplyOne,
    editEventReplyStatus,
    editStatus,
    editBannerShow,
    getDetail,
    delActivity
}
